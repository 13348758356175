<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import { trans } from '@plenny/translator';

  type Contact = {
    type: 'phone' | 'internal' | 'mobile' | 'fax' | 'email',
    value: string,
  }

  type Item = {
    icon: string | null,
    content: string | null,
    contacts: Contact[],
  }

  const props = defineProps({
    header: String,
    header_type: String,
    content: String,
    photos: Array,
    items: { type: Array as PropType<Item[]>, required: true },
  });

  const types = computed(() => {
    return {
      phone: trans('tel.'),
      internal: trans('tel. wew.'),
      mobile: trans('tel. kom.'),
      fax: trans('fax'),
      email: trans('e-mail'),
    };
  });

  const items = computed(() => {
    return props.items.filter((item) => !!item.content);
  });

  function makeContactHref(contact: Contact) {
    switch (contact.type) {
      case 'phone':
      case 'mobile':
      case 'fax':
        return `tel:${contact.value}`;
      case 'email':
        return `mailto:${contact.value}`;
      default:
        return 'javascript:void(0)';
    }
  }
</script>
<template>
  <Segment class="departments-cards" :title="header" :headerType="header_type || 'h2'">
    <div class="departments-cards__description" v-html="content" />
    <div class="departments-cards__departments">
      <div v-for="department in items" class="departments-cards__department">
        <span v-if="department.icon" class="departments-cards__icon" aria-hidden="true" v-html="department.icon" />
        <div class="departments-cards__content" v-if="department.contacts">
          <span v-if="department.content" v-html="department.content" />
          <div v-for="contact in department.contacts">
            <a :href="makeContactHref(contact)">
              {{ types[contact.type] + ':' }} {{ contact.value }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </Segment>
</template>
<style scoped lang="scss">
  .departments-cards {
    &__departments {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1.5rem;
    }

    &__name{
      font-weight: var(--font-weight-600);
      padding-bottom: 1.5rem;
      display: inline-block;
    }

    &__description{
      padding-bottom: 1rem;
    }

    &__department {
      background: var(--color-white);
      padding: 1.5rem;
      &::after{
        content: '';
        clear: both;
      }
    }

    &__icon {
      float: right;
      display: inline;
      margin: 0 0 0.5rem 0.5rem;

      :deep(svg) {
        height: 2rem;
        width: 2rem;
      }
    }
  }

  @media(max-width: 1200px) {
    .departments-cards {
      &__departments {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media(max-width: 992px) {
    .departments-cards {
      &__departments {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      &__department {
        padding: 1rem;

      }
    }
  }
</style>
