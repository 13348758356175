<template>
  <svg aria-label="Wod-kan logo" role="img" width="494" height="156" viewBox="0 0 494 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_32_6)">
      <path d="M493.599 75.107L394.519 149.193L300.005 89.662L432.573 71.7336V1.03107H494L493.599 75.107Z" fill="#ED1C24" />
      <path d="M442.697 71.7336L324.249 156L202.767 71.7336H373.139V1.03107H442.697V71.7336Z" fill="#0D52A5" />
      <path d="M0 2.09216L12.5378 57.5891H28.0798L34.7492 24.6853L41.9294 57.5891H57.3413L69.3583 2.09216H53.8163L49.2398 34.3452L41.9294 2.09216H28.6105L21.17 34.2952L15.6722 2.09216H0Z" fill="#231F20" />
      <path d="M121.372 28.269C120.13 8.80905 108.624 -0.870877 93.9331 0.0600777C80.3338 0.920961 70.8604 11.7521 70.1594 27.2179C69.2481 47.3886 80.0935 59.6112 95.2349 59.8614C110.196 60.1017 122.584 47.3986 121.362 28.259L121.372 28.269ZM102.686 42.5236C101.734 43.4346 100.272 45.4867 96.1362 45.5067C92.2507 45.5267 89.7672 43.855 89.0161 42.894C83.3381 35.5865 85.7515 20.2408 88.3652 17.4379C90.8688 14.7451 92.2808 14.565 96.006 14.3648C99.7313 14.1645 102.756 16.8973 103.417 18.1687C106.811 24.7654 107.202 38.2092 102.696 42.5236H102.686Z" fill="#231F20" />
      <path d="M170.331 13.4138C166.406 4.62476 155.57 2.18225 155.57 2.18225H127.751V57.6692H156.362C156.362 57.6692 165.775 55.1266 170.341 45.827C174.918 36.5175 174.647 23.0637 170.341 13.4038L170.331 13.4138ZM156.291 40.6117C154.869 43.6348 150.343 44.7559 150.343 44.7559L143.363 44.6658V15.0154L150.093 15.1055C150.093 15.1055 153.598 15.4158 155.841 18.6692C158.094 21.9325 158.845 35.1861 156.301 40.6017L156.291 40.6117Z" fill="#231F20" />
      <path d="M197.89 30.5614H177.121V44.7559H197.89V30.5614Z" fill="#231F20" />
      <path d="M235.223 22.423L253.269 2.18225H234.462L219.05 20.8614V2.18225H203.769V57.6792H219.05V42.0832L225.239 36.4974L237.106 57.6792H256.013L235.223 22.423Z" fill="#231F20" />
      <path d="M291.573 2.18225H274.92L256.994 57.6692H272.476L275.12 48.1394L291.283 48.2395L293.826 57.6692H309.699L291.573 2.18225ZM277.964 36.0069L283.352 16.2567L288.739 36.0069H277.964Z" fill="#231F20" />
      <path d="M326.423 25.0757V57.6792H312.573V2.18225H325.371L345.49 33.9548V2.18225H359.339V57.6792H346.531L326.423 25.0757Z" fill="#231F20" />
    </g>
    <defs>
      <clipPath id="clip0_32_6">
        <rect width="494" height="156" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>