<script setup>
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  defineProps({
    title: { type: String, required: false },
    link: { type: String, required: false },
    headerType: { type: String, required: false },
  });
</script>
<template>
  <section class="segment">
    <header class="segment__header" v-if="title || link ">
      <SegmentHeading v-if="title" :type="headerType || 'h2'">
        {{ title }}
      </SegmentHeading>
      <SfButton v-if="link" transparent class="segment__btn" :href="link" :small="!breakpoints.md">
        <template #default>
          {{ $t('Zobacz więcej') }}
        </template>
        <template #after>
          <IconChevronRight />
        </template>
      </SfButton>
    </header>
    <div class="segment__content">
      <slot />
    </div>
  </section>
</template>

<style scoped lang="scss">
  .segment {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &__content {
      margin-top: 1.5rem;
    }
  }

  @media(max-width: 992px) {
    .segment {
      padding: 0 0.625rem;

      &__content {
        margin-top: 0.375rem;
      }
    }
  }
</style>
