<script setup lang="ts">
  import { ref, type PropType } from 'vue';

  type Brand = {
    id: number;
    name: string;
    slug: string;
    photo: string | null;
  }

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: { type: String, required: false },
    brands: { type: Array as PropType<Brand[]>, required: true },
    header_type: String,
    link: String,
  });

  const parameters = ref({
    spaceBetween: 16,
    slidesPerView: 3.5,
    breakpoints: {
      576: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 6,
      },
      992: {
        slidesPerView: 8,
      },
      1200: {
        slidesPerView: 10,
      },
    },
  });
</script>
<template>
  <Segment :title="header" :link :headerType="header_type">
    <SfBrandsCarousel :brands :parameters />
  </Segment>
</template>
<style scoped lang="scss">
  .brands-carousel :deep(.brands-carousel__container) {
    picture img {
      height: 85px;
    }
  }

  @media(max-width: 576px) {
    .segment {
      padding-right: 0;
    }
  }
</style>
