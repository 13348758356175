<script setup lang="ts">
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    header_type: String,
    link: String,
    products: { type: Array, required: true },
  });

  const parameters = ref({
    spaceBetween: 24,
    slidesPerView: 1.5,
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });
</script>
<template>
  <Segment :title="header" :link :headerType="header_type">
    <SfProductsCarousel :products :parameters />
  </Segment>
</template>
<style scoped lang="scss">
  @media(max-width: 576px) {
    .segment {
      padding-right: 0;
    }
  }
</style>
